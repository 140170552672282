const RESULTS_SELECTOR = '.js-article-results'
const PAGINATION_SELECTOR = '.js-pagination'
const ARTICLE_IMAGE_ELECTOR = '.js-article-image'

class BlogLanding extends HTMLElement {
  constructor () {
    super()
    const resultsEl = this.querySelector(RESULTS_SELECTOR)
    if (!resultsEl) return
    this.blogContent(resultsEl)
  }

  getCurrentPage () {
    const urlParams = new URLSearchParams(window.location.search)
    const page = urlParams.get('page')
    return page ? parseInt(page) : 1
  }

  getPaginationRange(currentPage, totalPages, maxVisiblePages = 5) {
    const range = []
    const half = Math.floor(maxVisiblePages / 2)

    let startPage = currentPage - half > 0 ? currentPage - half : 1
    let endPage = currentPage + half < totalPages ? currentPage + half : totalPages

    if (currentPage <= half) {
      startPage = 1
      endPage = maxVisiblePages < totalPages ? maxVisiblePages : totalPages
    }

    if (currentPage > totalPages - half) {
      startPage = totalPages - maxVisiblePages + 1 > 1 ? totalPages - maxVisiblePages + 1 : 1
      endPage = totalPages
    }

    if (startPage > 1) {
        range.push(1)
        if (startPage > 2) {
          range.push('...')
        }
    }

    for (let i = startPage; i <= endPage; i++) {
      range.push(i)
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        range.push('...')
      }
      range.push(totalPages)
    }
    return range
  }

  getUrlParam(name, url = window.location.href) {
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  buildPagination (range, currentPage) {
    const urlParams = new URLSearchParams(window.location.search)
    const getQueryString = urlParams.toString().replace(/([&?]?page=\d+)/g, '')
    let queryString = getQueryString
    if (!this.getUrlParam('page')) {
      queryString = `&${getQueryString}`
    }
    let markup = ''
    const iconHtml = `
      <span class="icon icon-new arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
          <path d="M1.11584 0.768188L4.8776 5.11845L1.11584 9.23214" stroke="#F9F7F3" stroke-width="1.41066"></path>
        </svg>
      </span>
    `
    if (currentPage == 1) markup += `
      <div class="pagination__item pagination__item--arrow">
        <span class="bg-transparent pagination__navigation-button pagination__navigation-button--previous disabled" aria-hidden="true">
          ${iconHtml}
        </span>
      </div>
    `

    if (currentPage > 1) markup += `
      <div class="pagination__item pagination__item--arrow">
        <a href="${window.location.pathname}?page=${currentPage - 1}${queryString}" class="bg-transparent pagination__navigation-button pagination__navigation-button--previous" aria-label="Previous page of blog posts">
          ${iconHtml}
        </a>
      </div>
    `

    markup += '<ul class="pagination__list">'

    range.forEach(page => {
      if (page === currentPage) {
        markup += `<li class="pagination__item pagination__item--active"><span class="px-1 bg-transparent pagination__navigation-button ff-body fs-body-60">${page}</span></li>`
      } else if (typeof page === 'string') {
        markup += `<li class="pagination__item pagination__item--ellip"><span class="px-1 bg-transparent pagination__navigation-button ff-body fs-body-60">${page}</span></li>`
      } else {
        markup += `<li class="pagination__item"><a href="${window.location.pathname}?page=${page}${queryString}" class="bg-transparent pagination__navigation-button ff-body fs-body-60">${page}</a></li>`
      }
    })

    markup += '</ul>'
    if (currentPage < range[range.length - 1]) markup += `
      <div class="pagination__item pagination__item--arrow">
        <a href="${window.location.pathname}?page=${currentPage + 1}${queryString}" class=" pagination__navigation-button pagination__navigation-button--next" aria-label="Next page of blog posts">
          ${iconHtml}
        </a>
      </div>
    `

    if (currentPage == range[range.length - 1]) markup += `
      <div class="pagination__item pagination__item--arrow">
        <span class=" pagination__navigation-button pagination__navigation-button--next disabled" aria-hidden="true">
          ${iconHtml}
        </span>
      </div>
    `
    return `<div class="flex items-center justify-between w-full gap-0 xs:gap-8 sm:gap-[60px] sm:w-auto">${markup}</div>`
  }

  fetchData = async (url) => {
    try {
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.text()
      return data

    } catch (error) {
      throw new Error(`Failed to fetch data: ${error.message}`)
    }
  }

  getAllBlogResults = async (totalPages) => {
    const chain = []
    for (let i = 1; i <= totalPages; i++) {
      chain.push(this.fetchData(`${window.location.pathname}?view=data-json&page=${i}`))
    }

    const allResults = await Promise.all(chain)
    const combinedResults = []

    allResults.forEach(htmlString => {
      const parser = new window.DOMParser()
      const doc = parser.parseFromString(htmlString, 'text/html')
      const results = [...doc.querySelectorAll('.js-article-item')].map(element => element.innerHTML)
      combinedResults.push(...results)
    })

    return combinedResults
  }

  paginatedResults (data, page, perPage) {
    const from = (page * perPage) - perPage
    const to = (page * perPage)
    return data.slice(from, to)
  }

  removeLoadImage (resultsEl) {
    const articleImageEls = resultsEl.querySelectorAll(ARTICLE_IMAGE_ELECTOR)
    if (!articleImageEls || !articleImageEls.length) return
    articleImageEls.forEach(img => {
      img.classList.remove('is-load')
    })
  }

  async blogContent (resultsEl) {
    const blogPagination = this.querySelector(PAGINATION_SELECTOR)
    const totalCount = parseInt(this.dataset?.count || 0)
    const pageSize =  parseInt(this.dataset?.paginateBy || 12)
    const totalPages = Math.ceil(totalCount / pageSize)
    const data = await this.getAllBlogResults(Math.ceil(totalCount / 12))
    if (data.length < 1) {
      this.removeLoadImage(resultsEl)
      return
    }
    let currentPage = this.getCurrentPage()
    const results = this.paginatedResults(data, currentPage, pageSize)
    const paginationRange = this.getPaginationRange(currentPage, totalPages, 4)

    if (!results || results.length < 1) return

    resultsEl.innerHTML = ''
    results.forEach(snippet => {
      resultsEl.innerHTML += snippet
    })

    if (paginationRange.length > 1 && blogPagination) {
      const paginationMarkup = this.buildPagination(paginationRange, currentPage)
      blogPagination.innerHTML = paginationMarkup
    }
  }
}

window.customElements.define('blog-landing', BlogLanding)
